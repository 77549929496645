import React from 'react';

import SimpleService from './Common/SimpleService';

export default function IndeedReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Indeed Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Returns reviews from a list of companies'
      serviceName='indeed_reviews'
      queriesPlaceholder='https://www.indeed.com/cmp/TEKsystems&#10;TEKsystems'
      apiTag='Reviews-and-Comments/paths/~1indeed~1reviews/get'
      defaultParams={{ sort: null }}
      recentCutoffSorting={null}
      sortingOptions={[
        { value: null, text: 'Date' },
        { value: 'helpfulness', text: 'Helpfulness' },
        { value: 'rating_desc', text: 'Rating' },
      ]}
    />
  );
}
