import React from 'react';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import dayjs from 'dayjs';

import history from '../../utils/history';
import { textToArray } from './Common/Google/utils';
import BaseService from './Common/BaseService';
import CustomQueries from './Common/Selects/CustomQueries';
import LanguageSelect from './Common/Google/LanguageSelect';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import AdvancedParameters from './Common/AdvancedParameters';
import ReviewsSortingFields from './Common/Google/PlayReviewsSortingFields';

export default function GooglePlayReviews() {
  const { hl: urlLanguage } = queryString.parse(history.location.search);
  const taskExtraDefaultParams = {
    limit_per_query: 120,

    sort: 'most_relevant',
    rating: null,
    cutoff: null,

    language: urlLanguage ? urlLanguage : (localStorage.getItem('language') || 'en'),
  };
  const { t } = useTranslation();

  function formatTask(task) {
    const { cutoff, queries } = task;
    return {
      ...task,
      queries: textToArray(queries),
      cutoff: cutoff ? cutoff.unix() : null
    };
  }

  function deformatTask(task) {
    const { cutoff } = task;
    return { ...task, cutoff: cutoff ? dayjs(cutoff*1000) : null };
  }

  return (
    <BaseService
      title='Google Play Reviews Scraper'
      subTitle='Returns reviews from a list of apps'
      serviceName='google_play_reviews_service'
      startTaskButtonTitle='Scrape Reviews'
      unitName='review'
      learnMoreUrl='https://outscraper.com/google-play-reviews-scraper'
      taskExtraDefaultParams={taskExtraDefaultParams}
      formatTask={formatTask}
      deformatTask={deformatTask}
      FormBody={({ task, updateTask }) => {
        const { queries, limit_per_query, language, input_file: inputFile, settings } = task;

        function onSetLanguage(value) {
          updateTask({ language: value });
          localStorage.setItem('language', value);
        }

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          <Form.Item
            required
            label={<span>
              Apps, movies, books links or IDs
              <InfoTooltip title={<>Copy links to apps, movies or books</>}/>
            </span>}
            className='formItem'
            style={{ maxWidth: 800 }}
          >
            <CustomQueries
              value={queries}
              onChange={(v) => updateTask({ queries: v })}
              inputFile={inputFile}
              onFileUploaded={onFileUploaded}
              placeholder='https://play.google.com/store/apps/details?id=com.facebook.katana&#10;https://play.google.com/store/movies/details/Titanic_2012_Re_Release?id=4S2Ruqhcz78&#10;https://play.google.com/store/books/details/Leo_Tolstoy_War_and_Peace?id=bTDscs8_1sUC&#10;com.skype.raider'
            />
          </Form.Item><br/>

          <ReviewsSortingFields task={task} onChange={updateTask}/>

          <Form.Item label={t('title.perQueryLimit', 'Limit per one query')} className='formItem'>
            <InputNumber
              min={1}
              step={40}
              value={limit_per_query}
              onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
            />
            <InfoTooltip title='Parameter specifies the limit of reviews from one item (app/movie/book).'/>
          </Form.Item><br/>

          <AdvancedParameters>
            <Form.Item label={t('title.language', 'Language')} className='formItem'>
              <LanguageSelect onChange={onSetLanguage} value={language}/>
            </Form.Item><br/>
          </AdvancedParameters>
        </>;
      }}
    />
  );
}
