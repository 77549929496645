import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import config from 'react-global-configuration';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';

import history from './utils/history';
import configureStore from './redux/store';
import Auth from './auth/Auth';
import Signup from './auth/Signup';
import ReferrerRedirect from './auth/ReferrerRedirect';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ResetPasswordConfirm from './auth/ResetPasswordConfirm';
import KYC from './auth/KYC';
import Logout from './auth/Logout';
import Subsribe from './auth/Subsribe';
import VerifyEmail from './auth/VerifyEmail';
import PrivateRoute from './utils/PrivateRoute';
import Sider from './Components/Navigation/Sider';
import GoogleMapsScraper from './Components/Services/GoogleMaps';
import PlacesByDomainsSearch from './Components/Services/PlacesByDomainsSearch';
import PlacesSearch from './Components/Services/PlacesSearch';
import PlacesUpdate from './Components/Services/PlacesUpdate';
import GoogleSearch from './Components/Services/GoogleSearch/GoogleSearch';
import GoogleSearchShopping from './Components/Services/GoogleSearch/GoogleSearchShopping';
import GoogleSearchImages from './Components/Services/GoogleSearch/GoogleSearchImages';
import GoogleSearchNews from './Components/Services/GoogleSearch/GoogleSearchNews';
import GoogleSearchVideos from './Components/Services/GoogleSearch/GoogleSearchVideos';
import GoogleSearchCareers from './Components/Services/GoogleSearch/GoogleSearchCareers';
import GoogleSearchJobs from './Components/Services/GoogleSearch/GoogleSearchJobs';
import GoogleSearchEvents from './Components/Services/GoogleSearch/GoogleSearchEvents';
import GoogleMapsDirectoryPlaces from './Components/Services/GoogleSearch/GoogleMapsDirectoryPlaces';
import GoogleMapsReviewsExtractor from './Components/Services/GoogleMapsReviews';
import GoogleMapsContributorReviews from './Components/Services/GoogleMapsContributorReviews';
import GoogleMapsPhotos from './Components/Services/GoogleMapsPhotos';
import GooglePlayReviews from './Components/Services/GooglePlayReviews';
import AppStoreReviews from './Components/Services/AppStoreReviews';
import AirbnbReviews from './Components/Services/AirbnbReviews';
import AirbnbSearch from './Components/Services/AirbnbSearch';
import GoogleProductsReviews from './Components/Services/GoogleProductsReviews';
import GoogleTrends from './Components/Services/GoogleTrends';
import DomainContacts from './Components/Services/Enrichers/Lookup/DomainContacts/DomainContacts';
import EmailValidator from './Components/Services/Enrichers/Lookup/EmailValidator/EmailValidator';
import FreeEmailsValidator from './Components/Services/Enrichers/Lookup/EmailValidator/EmailsValidator';
import FreeEmailsFinder from './Components/Services/Enrichers/Lookup/EmailsFinder';
import GoogleMapsTrafficExtractor from './Components/Services/GoogleMapsTrafficExtractor';
import AmazonProductsExtractor from './Components/Services/AmazonProductsExtractor';
import AmazonReviewsExtractor from './Components/Services/AmazonReviewsExtractor';
import ShopifyProductsScraper from './Components/Services/ShopifyProductsScraper';
import ShopeeReviews from './Components/Services/ShopeeReviews';
import EbayProducts from './Components/Services/EbayProducts';
import DomainsExtractor from './Components/Services/DomainsExtractor';
import ContactsFinder from './Components/Services/ContactsFinder';
import CompanyInsights from './Components/Services/CompanyInsights';
import USPhonesEnricher from './Components/Services/Enrichers/USPhonesEnricher';
import PhonesEnricher from './Components/Services/Enrichers/PhonesEnricher';
import WhatsappChecker from './Components/Services/Enrichers/WhatsappChecker';
import WhatsappSender from './Components/Services/Actors/WhatsappSender';
import ImessageChecker from './Components/Services/Enrichers/ImessageChecker';
import HubSpotContactEmailsFinder from './Components/Services/Enrichers/HubSpotContactEmailsFinder';
import HubSpotCompanyContactsFinder from './Components/Services/Enrichers/HubSpotCompanyContactsFinder';
import EmailsValidator from './Components/Services/Validators/EmailsValidator';
import EmailFinder from './Components/Services/Enrichers/EmailFinder';
import DisposableEmailChecker from './Components/Services/Validators/DisposableEmailChecker';
import YelpScraper from './Components/Services/YelpScraper';
import ZillowSearch from './Components/Services/ZillowSearch';
import YellowPagesSearchScraper from './Components/Services/YellowPagesSearchScraper';
import YelpReviewsScraper from './Components/Services/YelpReviewsScraper';
import YelpPhotosScraper from './Components/Services/YelpPhotosScraper';
import BookingReviewsScraper from './Components/Services/BookingReviewsScraper';
import BookingSearchScraper from './Components/Services/BookingSearchScraper';
import TargetReviewsScraper from './Components/Services/TargetReviewsScraper';
import ExpediaSearchScraper from './Components/Services/ExpediaSearchScraper';
import ExpediaReviewsScraper from './Components/Services/ExpediaReviewsScraper';
import GetappReviewsScraper from './Components/Services/GetappReviewsScraper';
import G2ReviewsScraper from './Components/Services/G2ReviewsScraper';
import IndeedSearchScraper from './Components/Services/IndeedSearchScraper';
import IndeedReviewsScraper from './Components/Services/IndeedReviewsScraper';
import GlassdoorSearchScraper from './Components/Services/GlassdoorSearchScraper';
import GlassdoorReviewsScraper from './Components/Services/GlassdoorReviewsScraper';
import HomedepotReviews from './Components/Services/HomedepotReviews';
import WalmartReviews from './Components/Services/WalmartReviews';
import TrustpilotScraper from './Components/Services/TrustpilotScraper';
import TrustpilotReviewsScraper from './Components/Services/TrustpilotReviewsScraper';
import TrustpilotSearchScraper from './Components/Services/TrustpilotSearchScraper';
import TripadvisorReviewsScraper from './Components/Services/TripadvisorReviewsScraper';
import TripadvisorSearch from './Components/Services/TripadvisorSearch';
import LinkedinCompaniesScraper from './Components/Services/LinkedinCompaniesScraper';
import LinkedinPeopleScraper from './Components/Services/LinkedinPeopleScraper';
import LinkedinPostsScraper from './Components/Services/LinkedinPostsScraper';
import Geocoding from './Components/Services/Geocoding';
import ReverseGeocoding from './Components/Services/ReverseGeocoding';
import FacebookPagesScraper from './Components/Services/FacebookPagesScraper';
import TwitterProfilesScraper from './Components/Services/TwitterProfilesScraper';
import TiktokProfilesScraper from './Components/Services/TiktokProfilesScraper';
import InstagramProfilesScraper from './Components/Services/InstagramProfilesScraper';
import FacebookSearchScraper from './Components/Services/FacebookSearchScraper';
import OnlyfansProfilesScraper from './Components/Services/OnlyfansProfilesScraper';
import YouTubeCommentsScraper from './Components/Services/YouTubeCommentsScraper';
import YouTubeChannelsScraper from './Components/Services/YouTubeChannelsScraper';
import YouTubeSearchScraper from './Components/Services/YouTubeSearchScraper';
import FacebookReviews from './Components/Services/FacebookReviews';
import CapterraReviews from './Components/Services/CapterraReviews';
import ProducthuntReviews from './Components/Services/ProducthuntReviews';
import Linkedin from './Components/Services/Linkedin';
import SMSMessaging from './Components/Services/Actors/SMSMessaging';
import AIScraper from './Components/Services/AIScraper';
import WhitepagesPhones from './Components/Services/WhitepagesPhones';
import WhitepagesAddresses from './Components/Services/WhitepagesAddresses';
import FastbackgroundcheckAddresses from './Components/Services/FastbackgroundcheckAddresses';
import UniversalAIScraper from './Components/Services/UniversalAIScraper';
import DomainContactsFinder from './Components/Services/DomainContactsFinder';
import NotYetCreatedService from './Components/Services/Actors/NotYetCreatedService';
import Tasks from './Components/Tasks/Tasks';
import SharedTaskResults from './Components/Tasks/SharedTaskResults';
import Profile from './Components/Profile/Profile';
import Invoices from './Components/Invoices/Invoices';
import BillingInformation from './Components/Profile/BillingInformation';
import ApiUsage from './Components/API/ApiUsage';
import ApiDocs from './Components/API/ApiDocs';
import WebhookCalls from './Components/WebhookCalls/WebhookCalls';
import MakeCryptoPayment from './Components/Profile/AddCredits/Crypto/MakeCryptoPayment';
import CryptoPayment from './Components/Profile/AddCredits/Crypto/CryptoPayment';
import Integrations from './Components/Integrations/Integrations';
import { addUrlParams } from './utils/utils';

const StyledDiv = styled.div`
  background: #fff;
  padding-top: 5px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  min-height: 100%;
`;

const auth = new Auth();
const googleClientId = config.get('googleClientId');

const PLAIN_PAGES = new Set(['/domain-contacts-lookup', '/email-validator-lookup', '/emails-validator-lookup', '/r', '/refer', '/signup-confirm', '/emails-finder-lookup', '/login', '/signup', '/reset-password']);

export default function App() {
  const { location } = history;
  const { pathname } = location;
  const { profile } = auth;
  const { ie } = profile;

  const showSider = !PLAIN_PAGES.has('/' + pathname.split('/')[1]) && !pathname.includes('/shared/');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === '1') {
      const expiresAt = localStorage.getItem('expiresAt');

      if (expiresAt) {
        const difference = (Number(expiresAt) - new Date().getTime()) / 1000 / 24 / 60 / 60;
        if (difference < 29) {
          auth.renewSession();
        }
      }
    }

    if (showSider || pathname === '/login' || pathname === '/signup') {
      const language = i18n.language.split('-')[0];
      const newParams = addUrlParams(location.search, { ln: language === 'en' ? null : language }, true);
      window.history.replaceState(null, null, newParams ? `?${newParams}` : window.location.pathname);
    }

    const intervalId = setInterval(() => {
      if (localStorage.getItem('isLoggedIn') === '1') {
        auth.renewSession();
      }
    }, 1000 * 60 * 60 * 12);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        {ie && <>God Mode: {ie}</>}
        {showSider &&
          <Layout style={{ minHeight: '100vh' }}>
            <Sider auth={auth} history={history}/>
            <Layout>
              <Layout.Content style={{ padding: '10px 10px' }}>
                <StyledDiv>
                  <Switch>
                    <Route exact path='/'><Redirect to='/profile'/></Route>
                    <PrivateRoute exact path='/googleMaps' component={GoogleMapsScraper} auth={auth} location={location} version={2} />
                    <PrivateRoute exact path='/google-maps' component={GoogleMapsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-places-domain-search' component={PlacesByDomainsSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/places-update' component={PlacesUpdate} auth={auth} location={location} />
                    <PrivateRoute exact path='/places-search' component={PlacesSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search' component={GoogleSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-shopping' component={GoogleSearchShopping} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-images' component={GoogleSearchImages} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-news' component={GoogleSearchNews} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-videos' component={GoogleSearchVideos} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-events' component={GoogleSearchEvents} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-search-jobs' component={GoogleSearchJobs} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-careers' component={GoogleSearchCareers} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-directory-places' component={GoogleMapsDirectoryPlaces} auth={auth} location={location} />
                    <PrivateRoute exact path='/googleReviews' component={GoogleMapsReviewsExtractor} auth={auth} location={location} version={3} />
                    <PrivateRoute exact path='/google-maps-reviews' component={GoogleMapsReviewsExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-maps-contributor-reviews' component={GoogleMapsContributorReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-maps-photos' component={GoogleMapsPhotos} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-play-reviews' component={GooglePlayReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/appstore-reviews' component={AppStoreReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/airbnb-search' component={AirbnbSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/airbnb-reviews' component={AirbnbReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-shopping-reviews' component={GoogleProductsReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/googleTraffic' component={GoogleMapsTrafficExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-traffic' component={GoogleMapsTrafficExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/google-trends' component={GoogleTrends} auth={auth} location={location} />
                    <PrivateRoute exact path='/amazon-products' component={AmazonProductsExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/amazon-reviews' component={AmazonReviewsExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/shopify-products' component={ShopifyProductsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/shopee-reviews' component={ShopeeReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/ebay-products' component={EbayProducts} auth={auth} location={location} />
                    <PrivateRoute exact path='/emails-scraper' component={DomainsExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/contacts-finder' component={ContactsFinder} auth={auth} location={location} />
                    <PrivateRoute exact path='/company-insights' component={CompanyInsights} auth={auth} location={location} />
                    <PrivateRoute exact path='/domains-scraper' component={DomainsExtractor} auth={auth} location={location} />
                    <PrivateRoute exact path='/phones-enricher' component={PhonesEnricher} auth={auth} location={location} />
                    <PrivateRoute exact path='/us-phones-enricher' component={USPhonesEnricher} auth={auth} location={location} />
                    <PrivateRoute exact path='/whatsapp-checker' component={WhatsappChecker} auth={auth} location={location} />
                    <PrivateRoute exact path='/whitepages-phones' component={WhitepagesPhones} auth={auth} location={location} />
                    <PrivateRoute exact path='/whitepages-addresses' component={WhitepagesAddresses} auth={auth} location={location} />
                    <PrivateRoute exact path='/fastbackgroundcheck-addresses' component={FastbackgroundcheckAddresses} auth={auth} location={location} />
                    <PrivateRoute exact path='/whatsapp-sender' component={WhatsappSender} auth={auth} location={location} />
                    <PrivateRoute exact path='/imessage-checker' component={ImessageChecker} auth={auth} location={location} />
                    <PrivateRoute exact path='/messaging' component={SMSMessaging} auth={auth} location={location} />
                    <PrivateRoute exact path='/emailing' auth={auth} location={location} render={(props) => <NotYetCreatedService title='Bulk Emailing' subTitle='Sends emails to a list of recipients' {...props} auth={auth}/>}/>
                    <PrivateRoute exact path='/imessage-sender' auth={auth} location={location} render={(props) => <NotYetCreatedService title='iMessage Sender' subTitle='Sends messages to a list of recipients via iMessage' {...props} auth={auth}/>}/>
                    <PrivateRoute exact path='/telegram-checker' auth={auth} location={location} render={(props) => <NotYetCreatedService title='Telegram Checker' subTitle='Checks whether a phone is connected to Telegram' {...props} auth={auth}/>}/>
                    <PrivateRoute exact path='/wechat-checker' auth={auth} location={location} render={(props) => <NotYetCreatedService title='WeChat Checker' subTitle='Checks whether a phone is connected to WeChat' {...props} auth={auth}/>}/>
                    <PrivateRoute exact path='/emails-validator' component={EmailsValidator} auth={auth} location={location} />
                    <PrivateRoute exact path='/email-finder' component={EmailFinder} auth={auth} location={location} />
                    <PrivateRoute exact path='/disposable-email-checker' component={DisposableEmailChecker} auth={auth} location={location} />
                    <PrivateRoute exact path='/linkedin' component={Linkedin} auth={auth} location={location} />
                    <PrivateRoute exact path='/yelp' component={YelpScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/yelp-reviews' component={YelpReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/yelp-photos' component={YelpPhotosScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/booking-search' component={BookingSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/booking-reviews' component={BookingReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/expedia-search' component={ExpediaSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/expedia-reviews' component={ExpediaReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/getapp-reviews' component={GetappReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/g2-reviews' component={G2ReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/target-reviews' component={TargetReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/homedepot-reviews' component={HomedepotReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/walmart-reviews' component={WalmartReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/indeed-search' component={IndeedSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/indeed-reviews' component={IndeedReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/glassdoor-search' component={GlassdoorSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/glassdoor-reviews' component={GlassdoorReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/yellowpages' component={YellowPagesSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/trustpilot' component={TrustpilotScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/trustpilot-reviews' component={TrustpilotReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/trustpilot-search' component={TrustpilotSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/zillow-search' component={ZillowSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/tripadvisor-reviews' component={TripadvisorReviewsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/tripadvisor-search' component={TripadvisorSearch} auth={auth} location={location} />
                    <PrivateRoute exact path='/linkedin-companies' component={LinkedinCompaniesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/linkedin-profiles' component={LinkedinPeopleScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/linkedin-posts' component={LinkedinPostsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/facebook-pages' component={FacebookPagesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/facebook-search' component={FacebookSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/facebook-reviews' component={FacebookReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/instagram-profiles' component={InstagramProfilesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/twitter-profiles' component={TwitterProfilesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/tiktok-profiles' component={TiktokProfilesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/capterra-reviews' component={CapterraReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/producthunt-reviews' component={ProducthuntReviews} auth={auth} location={location} />
                    <PrivateRoute exact path='/onlyfans-profiles' component={OnlyfansProfilesScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/youtube-comments' component={YouTubeCommentsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/youtube-channels' component={YouTubeChannelsScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/youtube-search' component={YouTubeSearchScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/hubspot-contact-emails-finder' component={HubSpotContactEmailsFinder} auth={auth} location={location} />
                    <PrivateRoute exact path='/hubspot-company-contacts-finder' component={HubSpotCompanyContactsFinder} auth={auth} location={location} />
                    <PrivateRoute exact path='/ai-scraper' component={AIScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/universal-scraper' component={UniversalAIScraper} auth={auth} location={location} />
                    <PrivateRoute exact path='/geocoding' component={Geocoding} auth={auth} location={location} />
                    <PrivateRoute exact path='/reverse-geocoding' component={ReverseGeocoding} auth={auth} location={location} />
                    <PrivateRoute exact path='/domain-contacts-finder' component={DomainContactsFinder} auth={auth} location={location} />
                    <PrivateRoute exact path='/profile' component={Profile} auth={auth} authForwarded={auth} location={location} />
                    <PrivateRoute exact path='/profile/crypto-payment' component={MakeCryptoPayment} auth={auth} location={location} />
                    <PrivateRoute exact path='/profile/crypto-payment/:paymentId' component={CryptoPayment} auth={auth} location={location} />
                    <PrivateRoute exact path='/invoices' component={Invoices} auth={auth} location={location}/>
                    <PrivateRoute exact path='/billing-info' component={BillingInformation} auth={auth} authForwarded={auth} location={location} />
                    <PrivateRoute exact path='/api-usage' component={ApiUsage} auth={auth} location={location} />
                    <PrivateRoute exact path='/webhook-calls' component={WebhookCalls} auth={auth} location={location} />
                    <PrivateRoute exact path='/tasks' component={Tasks} auth={auth} location={location} />

                    <Route exact path='/integrations' component={Integrations} auth={auth} location={location} />
                    <Route exact path='/api-docs' render={(props) => <ApiDocs {...props} auth={auth}/>} />

                    <Route exact path='/logout' render={(props) => <Logout {...props} auth={auth}/>} />
                    <Route exact path='/callback' render={(props) => <Logout {...props} auth={auth}/>} />
                    <Route exact path='/verify-email/:token' render={(props) => <VerifyEmail {...props} auth={auth}/>} />
                    <Route exact path='/subsribe' render={(props) => <Subsribe {...props} auth={auth} history={history} />} />
                  </Switch>
                </StyledDiv>
              </Layout.Content>
              <Layout.Footer style={{ textAlign: 'center' }}>
                <a target='_blank' rel='noreferrer' href='https://outscraper.com'>{`Copyright 2018-${new Date().getFullYear()} ${config.get('company')} ©`}</a>
              </Layout.Footer>
            </Layout>
          </Layout>
        }

        <GoogleOAuthProvider clientId={googleClientId}>
          <Route exact path='/login' render={(props) => <Login {...props} auth={auth} history={history} />} />
          <Route exact path='/signup' render={(props) => <Signup {...props} auth={auth} history={history} />} />
        </GoogleOAuthProvider>;
        <Route exact path='/reset-password' render={(props) => <ResetPassword {...props} auth={auth} history={history} />} />
        <Route exact path='/reset-password/:token' render={(props) => <ResetPasswordConfirm {...props} auth={auth} history={history} />} />

        <Route exact path='/r' render={(props) => <ReferrerRedirect {...props} history={history} />} />
        <Route exact path='/refer' render={(props) => <ReferrerRedirect {...props} history={history} />} />
        <Route exact path='/signup-confirm' render={(props) => <KYC {...props} auth={auth}/>} />
        <Route exact path='/shared/:userId/:taskId' component={SharedTaskResults} />
        <Route exact path='/domain-contacts-lookup' component={DomainContacts} />
        <Route exact path='/email-validator-lookup' component={EmailValidator} />
        <Route exact path='/emails-validator-lookup' component={FreeEmailsValidator} />
        <Route exact path='/emails-finder-lookup' component={FreeEmailsFinder} />
      </Router>
    </Provider>
  );
}
