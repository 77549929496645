import React from 'react';

import SimpleService from './Common/SimpleService';

export default function ProducthuntReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Product Hunt Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Returns reviews from a list of pages'
      serviceName='producthunt_reviews'
      queriesPlaceholder='https://www.producthunt.com/products/outscraper/reviews&#10;outscraper'
      apiTag='Others/paths/~1producthunt-reviews/get'
      defaultParams={{ sort: 'BEST' }}
      recentCutoffSorting={'LATEST'}
      sortingOptions={[
        { value: 'BEST', text: 'Best' },
        { value: 'LATEST', text: 'Most Recent' },
        { value: 'FAVORABLE', text: 'Most Positive' },
        { value: 'CRITICAL', text: 'Most Negative' },
      ]}
    />
  );
}
