import React from 'react';

import SimpleService from './Common/SimpleService';

export default function FacebookReviews() {
  return (
    <SimpleService
      beta
      unitName='review'
      title='Facebook Reviews Scraper'
      subTitle='Returns reviews from a list of pages'
      startTaskButtonTitle='Scrape Reviews'
      queriesLabel='URLs to FB pages, pages IDs'
      serviceName='facebook_reviews_service_v2'
      queriesPlaceholder='Protegepaloalto&#10;https://www.facebook.com/moodswinebar/&#10;https://www.facebook.com/pages/Siam-Royal-Authentic-Thai-Cuisine/108802532485636'
      apiTag='Facebook/paths/~1facebook~1reviews/get'
    />
  );
}
