import React from 'react';

import SimpleService from './Common/SimpleService';

export default function HomedepotReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Home Depot Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Returns reviews from a list of products'
      serviceName='homedepot_reviews'
      queriesPlaceholder='https://www.homedepot.com/p/Bordeaux-59-in-Acrylic-Flatbottom-Freestanding-Bathtub-in-White-Matte-Black-VA6815-S-MB/324608137'
      apiTag='Reviews-and-Comments/paths/~1homedepot~1reviews/get'
      defaultParams={{ sort: 'photoreview' }}
      recentCutoffSorting={'newest'}
      sortingOptions={[
        { value: 'photoreview', text: 'Photo Reviews' },
        { value: 'mosthelpfull', text: 'Most Helpful' },
        { value: 'oldest', text: 'Oldest' },
        { value: 'newest', text: 'Newest' },
        { value: 'highestrating', text: 'Highest rating' },
        { value: 'lowestrating', text: 'Lowest rating' },
      ]}
    />
  );
}
