import React from 'react';

import SimpleService from './Common/SimpleService';

export default function GetappReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='GetApp Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Returns reviews from a list of apps'
      serviceName='getapp_reviews'
      queriesPlaceholder='https://www.getapp.com/customer-management-software/a/salesforce/reviews/'
      apiTag='Reviews-and-Comments/paths/~1getapp~1reviews/get'
      defaultParams={{ sort: 'recommended' }}
      recentCutoffSorting={'most_recent'}
      sortingOptions={[
        { value: 'recommended', text: 'Recommended' },
        { value: 'most_recent', text: 'Most recent' },
        { value: 'least_recent', text: 'Least recent' },
        { value: 'highest_rated', text: 'Highest rated' },
        { value: 'lowest_rated', text: 'Lowest rated' },
      ]}
    />
  );
}
