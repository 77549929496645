import React from 'react';

import SimpleService from './Common/SimpleService';

export default function AppStoreReviews() {
  return (
    <SimpleService
      unitName='review'
      maxLimit={500}
      title='AppStore Reviews Scraper'
      startTaskButtonTitle='Scrape Reviews'
      subTitle='Returns reviews from a list of apps/podcasts'
      serviceName='appstore_reviews_service'
      queriesPlaceholder='https://apps.apple.com/us/app/telegram-messenger/id686449807&#10;id686449807'
      apiTag='Reviews-and-Comments/paths/~1appstore~1reviews/get'
      defaultParams={{ sort: 'mosthelpful' }}
      recentCutoffSorting={'mostrecent'}
      sortingOptions={[
        { value: 'mosthelpful', text: 'Most Helpful' },
        { value: 'mostrecent', text: 'Most Recent' },
      ]}
    />
  );
}
