export default {
  apiDomain: '127.0.0.1:5001',
  homeUrl: 'outscraper.com',
  company: 'Outscraper',
  contactUsUrl: 'https://outscraper.com/contact-us/',
  servicesUrl: 'https://outscraper.com/services/',
  pricingUrl: 'https://outscraper.com/pricing/',
  faqUrl: 'https://outscraper.com/faq/',
  tutorialsUrl: 'https://outscraper.com/category/scraping/tutorials/',
  videosUrl: 'https://www.youtube.com/channel/UCDYOuXSEenLpt5tKNq-0l9Q',
  communityUrl: 'https://community.outscraper.com/',
  termsOfServiceUrl: 'https://outscraper.com/terms-of-service/',
  googleMapsCategoriesUrl: 'https://outscraper.com/google-maps-business-categories/',
  stripeApiKeys: {
    main: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
    eu: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
    eu2: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
  },
  payPalClientId: 'AZTFoCJUBouxkynuKgSapwxt4ZX3SYiEwSncvc4eA9s_f95YQwV6awypMBpk3Ys4S6323rNA5MAVL3Xz',
  locationsFileUrl: 'https://outscraper.com/files/locations.csv',
  mixpanelToken: '',
  gaToken: 'G-1Y86YSLK68',
  fileUploaderDir: 'uploads/',
  hcaptchaSiteKey: '018d304b-5ec3-4c3e-84e1-898ddf8cdec8',
  taskExpiredAfter: 60,
  taskExpiredAfterSoft: 45,
  queriesSoftLimit: 45000,
  queriesHardLimit: 125000,
  googleClientId: '631032570211-dj87blpft7vcnmdjc39ti9si1rdrbvu2.apps.googleusercontent.com',
};
